import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import ROLES from 'utils/constants/roles'
import LINKS from 'utils/constants/links'

import { Collapse, Card, Button, Grid, Typography, CardContent, CardMedia } from 'components'
import makeStyles from '@mui/styles/makeStyles'

import MainLayout from 'components/containers/main/Main'
import { isRrpCertified } from 'utils/permissions/permissionsLogic'

const useStyles = makeStyles({
  grid: {
    justifyContent: 'center',
    padding: 20,
    paddingTop: 15,
  },
  title: {
    fontWeight: 'bold',
  },
  text: {
    whiteSpace: 'pre-wrap',
    fontSize: '14px',
    color: '#616161',
  },
  expanded: {
    color: '#000',
    transform: ({ expanded }) => (expanded ? 'rotate(180deg)' : 'rotate(0)'),
  },
  cardHeight: {
    height: '100%',
    width: '100%',
  },
  googleLogo: {
    height: 36,
    width: 107,
  },
  appleLogo: {
    height: 36,
    width: 119,
  },
  labelText: {
    whiteSpace: 'pre-wrap',
    fontSize: '12px',
    color: '#616161',
    textAlign: 'center',
  },
  divider: {
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    border: 'none',
    margin: 0,
    width: '37%',
  },
})

export function ProductCard({ pending, id, image, description, name, metadata }) {
  const [expanded, setExpanded] = React.useState(false)
  const classes = useStyles({ expanded })

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const borderSX = {
    borderTop: 10,
    borderBottom: 10,
    borderColor: metadata.borderColor,
  }

  return (
    <Card justify="center" className={classes.cardHeight}>
      <CardMedia
        sx={metadata?.borderColor ? borderSX : null}
        height="200"
        component="img"
        src={metadata.pictureUrl}
      />
      <CardContent style={{ paddingBottom: '0px' }}>
        <Typography className={`${classes.title} mt-2 mb-3`}>{name}</Typography>
        <Typography className={classes.text} paragraph>
          {description}
        </Typography>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Typography className={classes.text} style={{ color: '#616161' }} paragraph>
            {metadata.expandedContent}
          </Typography>
        </Collapse>
        <Grid container direction="row" justifyContent="space-around" alignItems="center">
          <Grid item className={classes.divider} />
          <Grid item>
            <Button onClick={handleExpandClick} aria-label="show more">
              <Typography variant="button">{expanded ? 'Less' : 'More'}</Typography>
            </Button>
          </Grid>
          <Grid item className={classes.divider} />
        </Grid>
        <div className="mt-1">
          <Typography className={classes.labelText}>
            This program requires the Unyte Health app.
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="py-3"
          >
            <Grid item>
              <a target="_blank" rel="noopener noreferrer" href={LINKS.download.playStore.href}>
                <img
                  alt="Android app"
                  className={classes.googleLogo}
                  src={LINKS.download.playStore.imgSrc}
                />
              </a>
            </Grid>
            <Grid item>
              <a target="_blank" rel="noopener noreferrer" href={LINKS.download.appStore.href}>
                <img
                  alt="App Store"
                  className={classes.appleLogo}
                  src={LINKS.download.appStore.imgSrc}
                />
              </a>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  )
}

export default function Products() {
  const classes = useStyles()
  const data = useSelector((state) => state.seats.products)
  const isClient = useSelector((state) => get(state, 'auth.user.roles', []).includes(ROLES.CLIENT))
  const authUser = useSelector((state) => state?.auth?.user)
  const rrpCertified = isRrpCertified({ authUser })
  const filteredData = data
    ?.filter((item) => item?.category !== 'assessment')
    .filter((item) => item?.category !== 'rrp' || (item?.category === 'rrp' && rrpCertified))
    .filter(Boolean)

  return (
    <MainLayout title="Programs" className={classes.grid}>
      <Grid container className="p-5">
        {filteredData
          ?.sort((a, b) => a.order - b.order)
          .map(({ name, description, metadata, id }, index) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              className={`${classes.card} p-2`}
              key={`product-${index}`}
            >
              <ProductCard name={name} id={id} description={description} metadata={metadata} />
            </Grid>
          ))}
        {!filteredData.length && isClient && (
          <div>
            <Typography gutterBottom variant="body2" className="py-2">
              Sorry but you currently don't have access to any available programs.
            </Typography>
            <Typography gutterBottom variant="body2" className="py-2">
              Please contact your Provider for more details.
            </Typography>
          </div>
        )}
        {!filteredData.length && !isClient && (
          <div>
            <Typography gutterBottom variant="body2" className="py-2">
              Sorry but you currently don't have access to any available programs. You might not
              have an active subscription.
            </Typography>
            <Typography gutterBottom variant="body2" className="py-2">
              Please contact Unyte Health support for more details.
            </Typography>
          </div>
        )}
      </Grid>
    </MainLayout>
  )
}
