import ROLES from '../constants/roles'
import { isLessThanXDaysAgo } from '../../utils/formatDate'
import {
  RRP_BANNER_STATE_0_NO_SHOW,
  RRP_BANNER_STATE_1_OFFER,
  RRP_BANNER_STATE_2_ACCEPTED,
  RRP_BANNER_STATE_3_ACTIVE,
  RRP_BANNER_STATE_4_PURCHASED,
  RRP_BANNER_STATE_5_TRIAL_EXPIRED,
  RRP_BANNER_STATE_6_MULTIPROVIDER,
} from 'utils/constants/rrp-banner-cards'

export const FREE_TRIAL_LENGTH_DAYS = 30
const EXTRA_DISPLAY_DAYS = 5

/* General Permissions Logic */
export function isBillingRole({ authUser }) {
  return authUser.roles.includes(ROLES.BILLING)
}

export function isInGoodStanding({ org }) {
  return !(
    !!org.hasUnpaidSspSubscription ||
    !!org.hasUnpaidRrpSubscription ||
    !!org.hasUnpaidFocusSubscription
  )
}

/* SSP */
export function isSspCertified({ authUser }) {
  return !!authUser.productPreferences?.sspCertification?.completedAt
}

export function sspCertifiedBilling({ authUser, org }) {
  return isBillingRole({ authUser }) && !!org?.hasOrgSspCertificationCompleted
}

export function isSspPreCertification({ authUser }) {
  return (
    !!authUser.productPreferences?.sspCertification?.enrolledAt &&
    !authUser.productPreferences?.sspCertification?.completedAt
  )
}

export function hasEnrolledSspCertification({ authUser }) {
  return !!authUser.productPreferences.sspCertification?.enrolledAt
}

export function hasActiveSspSubscription({ org }) {
  return !!org.hasPaidSspSubscription || !!org.hasPastDueSspSubscription
}

export function hasSspSubscription({ org }) {
  return (
    !!org.hasPaidSspSubscription ||
    !!org.hasPastDueSspSubscription ||
    !!org.hasPendingSspSubscription
  )
}

export function anyStateSspSubscription({ org }) {
  return (
    !!org.hasPendingSspSubscription ||
    !!org.hasPaidSspSubscription ||
    !!org.hasPastDueSspSubscription ||
    !!org.hasUnpaidSspSubscription ||
    !!org.hasAllCancelledSspSubscription
  )
}

/* RRP */
export function isRrpCertified({ authUser }) {
  return !!authUser?.productPreferences?.rrpCertification?.completedAt
}

export function rrpCertifiedBilling({ authUser, org }) {
  return isBillingRole({ authUser }) && !!org.hasOrgRrpCertificationCompleted
}

export function isRrpPreCertification({ authUser }) {
  return (
    !!authUser.productPreferences.rrpCertification?.enrolledAt &&
    !authUser.productPreferences.rrpCertification?.completedAt
  )
}

export function hasEnrolledRrpCertification({ authUser }) {
  return !!authUser.productPreferences.rrpCertification?.enrolledAt
}

export function hasActiveRrpSubscription({ org }) {
  return !!org.hasPaidRrpSubscription || !!org.hasPastDueRrpSubscription
}

export function hasRrpSubscription({ org }) {
  return (
    !!org.hasPaidRrpSubscription ||
    !!org.hasPastDueRrpSubscription ||
    !!org.hasPendingRrpSubscription
  )
}

export function anyStateRrpSubscription({ org }) {
  return (
    !!org.hasPendingRrpSubscription ||
    !!org.hasPaidRrpSubscription ||
    !!org.hasPastDueRrpSubscription ||
    !!org.hasUnpaidRrpSubscription ||
    !!org.hasAllCancelledRrpSubscription
  )
}

/* ILS */
export function isIlsCertified({ authUser }) {
  return !!authUser.productPreferences.focusCertification?.completedAt
}

export function ilsCertifiedBilling({ authUser, org }) {
  return isBillingRole({ authUser }) && !!org.hasOrgFocusCertificationCompleted
}

export function isIlsPreCertification({ authUser }) {
  return (
    !!authUser.productPreferences.focusCertification?.enrolledAt &&
    !authUser.productPreferences.focusCertification?.completedAt
  )
}

export function hasEnrolledIlsCertification({ authUser }) {
  return !!authUser.productPreferences.focusCertification?.enrolledAt
}

export function hasActiveIlsSubscription({ org }) {
  return !!org.hasPaidFocusSubscription || !!org.hasPastDueFocusSubscription
}

export function hasIlsSubscription({ org }) {
  return (
    !!org.hasPaidFocusSubscription ||
    !!org.hasPastDueFocusSubscription ||
    !!org.hasPendingFocusSubscription
  )
}

export function anyStateIlsSubscription({ org }) {
  return (
    !!org.hasPendingFocusSubscription ||
    !!org.hasPaidFocusSubscription ||
    !!org.hasPastDueFocusSubscription ||
    !!org.hasUnpaidFocusSubscription ||
    !!org.hasAllCancelledFocusSubscription
  )
}

export function hasDeferredRrpSubscription({ authUser }) {
  return !!authUser.organization?.settings?.rrpSkuToPurchaseAfterSample
}

export function isRRPTrialExpired({ authUser }) {
  const orgSettings = authUser.organization?.settings || {}

  const { rrpSampleActivatedAt } = orgSettings

  const trialExpired = rrpSampleActivatedAt
    ? !isLessThanXDaysAgo({ date: rrpSampleActivatedAt, interval: FREE_TRIAL_LENGTH_DAYS })
    : false

  return !!trialExpired
}

export function showRRPStoreFront({ org, authUser, ff }) {
  // is in good standing
  if (!isInGoodStanding({ org })) return false

  // when this variable is set, user has a deferred purchased
  if (hasDeferredRrpSubscription({ authUser })) return false

  // Has a cancelled RRP subscription */
  if (org.hasAllCancelledRrpSubscription) return true

  return (
    // Is an SSP subscriber
    hasActiveSspSubscription({ org, authUser }) &&
    // has a billing role, and is a SSP certified */
    sspCertifiedBilling({ org, authUser }) &&
    // newPlan and RRP feature flags are on */
    !!ff.showNewSubscriptionPlan &&
    !!ff.showRRPProduct &&
    // Hasn't already enrolled in Certification or purchased an RRP Subscription
    !(hasEnrolledRrpCertification({ authUser }) && hasRrpSubscription({ org }))
  )
}

export function getRRPFreeSampleState({ org, authUser }) {
  const orgSettings = authUser?.organization?.settings || {}
  const { showRRPFreeSample, rrpSampleActivatedAt, rrpSkuToPurchaseAfterSample } = orgSettings

  const hideTrial = rrpSampleActivatedAt
    ? !isLessThanXDaysAgo({
        date: rrpSampleActivatedAt,
        interval: FREE_TRIAL_LENGTH_DAYS + EXTRA_DISPLAY_DAYS,
      })
    : false
  if (hideTrial) return RRP_BANNER_STATE_0_NO_SHOW

  const trialExpired = isRRPTrialExpired({ authUser })
  if (trialExpired) return RRP_BANNER_STATE_5_TRIAL_EXPIRED

  if (
    !showRRPFreeSample ||
    !isInGoodStanding({ org }) ||
    !isSspCertified({ authUser }) ||
    hasRrpSubscription({ org })
  )
    return RRP_BANNER_STATE_0_NO_SHOW // Guard clause - don't show the page

  if (
    !trialExpired &&
    !isRrpCertified({ authUser }) &&
    org.hasOrgRrpCertificationCompleted &&
    !hasEnrolledRrpCertification({ authUser })
    // !rrpSkuToPurchaseAfterSample - multi user can join trial even when org has purchased a subscription
  )
    // todo: differentiate this state from single provider
    return RRP_BANNER_STATE_6_MULTIPROVIDER

  if (
    !rrpSampleActivatedAt && // check to see if this value has been set yet
    !rrpSkuToPurchaseAfterSample
  )
    return RRP_BANNER_STATE_1_OFFER

  if (
    !trialExpired &&
    !isRrpCertified({ authUser }) &&
    // org.hasOrgRrpCertificationCompleted && - Need to make sure the current user is not certified - not org
    hasEnrolledRrpCertification({ authUser }) &&
    !rrpSkuToPurchaseAfterSample
  )
    return RRP_BANNER_STATE_2_ACCEPTED

  if (
    !trialExpired &&
    isRrpCertified({ authUser }) &&
    // org.hasOrgRrpCertificationCompleted && - Need to make sure the current user is not certified - not org
    // hasEnrolledRrpCertification({ authUser }) &&
    !rrpSkuToPurchaseAfterSample
  )
    return RRP_BANNER_STATE_3_ACTIVE

  if (
    !trialExpired &&
    isRrpCertified({ authUser }) &&
    // org.hasOrgRrpCertificationCompleted && - Need to make sure the current user is not certified - not org
    // hasEnrolledRrpCertification({ authUser }) &&
    !!rrpSkuToPurchaseAfterSample
  )
    return RRP_BANNER_STATE_4_PURCHASED

  return RRP_BANNER_STATE_0_NO_SHOW
}
